import React from "react";
import Banner from "../components/Banner/Banner";
import AcercaDe from "../components/AcercaDe/AcercaDe";
import Servicios from "../components/Servicios/Servicios";

const Home = () => {
  return (
    <>
      <Banner />
      <AcercaDe />
      <Servicios />
    </>
  );
};

export default Home;
