import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import Seguridad from "./pages/Seguridad";
import Capacitaciones from "./pages/Capacitaciones";
import GestionAmbiental from "./pages/Gestion";
import Agenda from "./components/Servicios/Agenda";

function App() {
  return (
    <div className="overflow-hidden">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gestionambiental" element={<GestionAmbiental />} />
        <Route path="/seguridad" element={<Seguridad />} />
        <Route path="/capacitaciones" element={<Capacitaciones />} />
        <Route path="/#agenda" element={<Agenda />} />
      </Routes>
      <SocialMedia />
      <Footer />
    </div>
  );
}

export default App;
